type BackgroundColorKey =
  | 'WHEAT_LIGHT'
  | 'WHEAT_200'
  | 'WHEAT'
  | 'RHUBARB_LIGHT'
  | 'RHUBARB_200'
  | 'RHUBARB'
  | 'TEAL_LIGHT'
  | 'TEAL_200'
  | 'TEAL'
  | 'PETROL_LIGHT'
  | 'PETROL_200'
  | 'PETROL'
  | 'GRAY_LIGHT'
  | 'GRAY_200'
  | 'TEAL'
  | 'PETROL'
  | 'RHUBARB'
  | 'WHEAT'

export const backgroundColor = {
  WHEAT_LIGHT: 'bg-wheat-alternate',
  WHEAT_200: 'bg-wheat-200',
  WHEAT: 'bg-wheat',
  WHEAT_CONTAINER: 'bg-non-nxd-wheat-container',
  RHUBARB_LIGHT: 'bg-rhubarb-alternate',
  RHUBARB_200: 'bg-rhubarb-200',
  RHUBARB: 'bg-rhubarb',
  RHUBARB_CONTAINER: 'bg-non-nxd-rhubarb-container',
  TEAL_LIGHT: 'bg-teal-alternate',
  TEAL_200: 'bg-teal-200',
  TEAL: 'bg-teal',
  TEAL_CONTAINER: 'bg-non-nxd-teal-container',
  PETROL_LIGHT: 'bg-petrol-alternate',
  PETROL_200: 'bg-petrol-200',
  PETROL: 'bg-petrol',
  PETROL_CONTAINER: 'bg-non-nxd-petrol-container',
  GRAY_LIGHT: 'bg-neutral-alternate',
  GRAY_200: 'bg-gray-200',
  // background colors for card slider pagination
  ASH: 'bg-non-nxd-ash',
  QUARTZ_ROSE: 'bg-non-nxd-quartz-rose',
  SLATE_GRAY: 'bg-non-nxd-slate-gray',
  CHARCOAL_BLACK: 'bg-non-nxd-charcoal-black',
  // background colors for account overview
  CONTAINER_TERTIARY_GRAY: 'bg-gray-200',
  CONTAINER_TERTIARY_RHUBARB: 'bg-rhubarb-alternate',
  CONTAINER_TERTIARY_PETROL: 'bg-petrol-alternate',
  CONTAINER_TERTIARY_TEAL: 'bg-teal-alternate',
  CONTAINER_TERTIARY_WHEAT: 'bg-wheat-alternate',
}

export const getBackgroundColor = (backgroundColorText?: string | null) => {
  return (
    backgroundColor[backgroundColorText as BackgroundColorKey] ?? 'bg-neutral'
  )
}
